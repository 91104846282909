import { Select } from 'antd';
import { Property } from '../../../../types';
import style from './PropertyDropdownOption.module.scss';

const { Option } = Select;

const option = (item: Property) => {
  const { displayName, isRequired, matchedHeader, description } = item;
  return (
    <Option
      key={displayName}
      value={displayName}
      label={isRequired ? `${displayName} *` : displayName}
      className="propertyOption"
      disabled={!!matchedHeader}
    >
      {displayName}
      {isRequired && <span className={style.asterisk}>*</span>}
      <div className={style.propertyDescription}>{description}</div>
    </Option>
  );
};

export default option;
