import { useSelector } from 'react-redux';
import { DATA_IMPORT_SLICE } from '../reducers/DataImportSlice';
import { RootState } from '../types';

const usePreviousStep = () =>
  useSelector((state: RootState) =>
    state[DATA_IMPORT_SLICE].steps.find(
      (step) => step.id === state[DATA_IMPORT_SLICE].currentStepId - 1
    )
  );

export default usePreviousStep;
