import { useSelector } from 'react-redux';
import { RootState } from 'types';
import { DATA_IMPORT_SLICE } from '../reducers/DataImportSlice';

const useRequiredPropertiesMatched = () =>
  useSelector((state: RootState) =>
    state[DATA_IMPORT_SLICE].properties
      .filter((property) => property.isRequired)
      .every((property) => property.matchedHeader !== '')
  );

export default useRequiredPropertiesMatched;
