import './index.scss';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import rootReducer from 'reducers/rootReducer';
import { ReduxDevtools } from 'helpers/redux';
import { configureStore } from '@reduxjs/toolkit';
import App from './App';

const store = configureStore({
  reducer: rootReducer,
  devTools: ReduxDevtools,
});

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <Provider store={store}>
    <App />
  </Provider>
);
