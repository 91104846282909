const IconCheckmark: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg
    data-testid="checkbox-icon"
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="11" cy="11" r="11" fill="#0A5C91" />
    <path
      d="M16.3201 6L17.8253 7.31701L9.46292 16.874L4 11.4111L5.41421 9.99687L9.3655 13.9482L16.3201 6Z"
      fill="white"
    />
  </svg>
);

export default IconCheckmark;
