import { getValidationProcess } from 'api/getValidationProcess';
import { Notification } from 'components/Notification';
import { Spin } from 'components/Spin';
import { ErrorType } from 'enums';
import { genericError } from 'helpers/errorMessages';
import usePreviewState from 'hooks/usePreviewState';
import { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setPreviewState } from 'reducers/DataImportSlice';
import { PreviewProgressProps } from '.';
import style from './PreviewProgress.module.scss';

export const PreviewProgress: FC<PreviewProgressProps> = (props) => {
  const { validationStarted } = props;
  const dispatch = useDispatch();
  const isPreviewReady = usePreviewState();
  const [validationProgress, setValidationProgress] = useState(
    'Building preview...'
  );

  const checkValidationProgress = useCallback(async () => {
    try {
      if (validationStarted) {
        const { status } = await getValidationProcess();

        if (status?.toLowerCase().includes('finished')) {
          dispatch(setPreviewState(true));
        }

        setValidationProgress(status);
      }
    } catch (e) {
      Notification(ErrorType.GeneralError, {
        message: 'Error',
        description: genericError,
      });
    }
  }, [dispatch, validationStarted]);

  useEffect(() => {
    const intervalID = setInterval(() => {
      checkValidationProgress();
    }, 1000);
    if (isPreviewReady) clearInterval(intervalID);
    return () => {
      clearInterval(intervalID);
    };
  }, [isPreviewReady, checkValidationProgress]);

  return (
    <div className={style.previewLoader}>
      <Spin />
      <div className={style.loaderContent}>{validationProgress}</div>
    </div>
  );
};
