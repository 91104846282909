import { useSelector } from 'react-redux';
import { DATA_IMPORT_SLICE } from '../reducers/DataImportSlice';
import { RootState } from '../types';

const useRequiredPropertiesNotMatched = () =>
  useSelector(
    (state: RootState) =>
      state[DATA_IMPORT_SLICE].properties.filter(
        (property) =>
          property.isRequired &&
          (property.matchedHeader === '' ||
            property.matchedHeader === undefined)
      ).length
  );

export default useRequiredPropertiesNotMatched;
