import { FC } from 'react';
import { SpinPropTypes } from '.';
import style from './Spin.module.scss';

export const Spin: FC<SpinPropTypes> = (props) => {
  const { active = true } = props;
  return (
    <div className={style.spinnerWrapper} aria-label="loading">
      <svg
        className={style.spinner}
        viewBox="0 0 100 100"
        xmlns="http://www.w3.org/2000/svg"
        width="190"
        height="190"
      >
        <circle
          className={active ? style.spinner__action : ''}
          cx="50"
          cy="50"
          r="45"
          strokeWidth="2px"
        />
      </svg>
      <svg
        className={style.staticCircle}
        viewBox="0 0 100 100"
        xmlns="http://www.w3.org/2000/svg"
        width="190"
        height="190"
      >
        <circle cx="50" cy="50" r="45" strokeWidth="2px" />
      </svg>
    </div>
  );
};
