import { Property } from 'types';

export const mapFields = (properties: Property[]) => {
  const mappedFieldsArray: string[][] = [];
  properties.forEach((property) => {
    if (property.matchedHeader) {
      mappedFieldsArray.push([property.displayName, property.matchedHeader]);
    }
  });
  return Object.fromEntries(mappedFieldsArray);
};
