import { useSelector } from 'react-redux';
import { DATA_IMPORT_SLICE } from '../reducers/DataImportSlice';
import { RootState } from '../types';

const useStepById = (stepId: number) =>
  useSelector((state: RootState) =>
    state[DATA_IMPORT_SLICE].steps.find((step) => step.id === stepId)
  );

export default useStepById;
