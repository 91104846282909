const IconClose: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg
    width="13"
    height="13"
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ cursor: 'pointer' }}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.44444 0L0 1.44444L5.05556 6.5L0 11.5556L1.44444 13L6.5 7.94444L11.5556 13L13 11.5556L7.94444 6.5L13 1.44444L11.5556 0L6.5 5.05556L1.44444 0Z"
      fill="#2E6680"
    />
  </svg>
);

export default IconClose;
