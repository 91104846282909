const IconFile: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg
    width="24"
    height="32"
    viewBox="0 0 24 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22 2H2V30H22V2ZM0 0V32H24V0H0ZM4 5H8V7H4V5ZM8 9H4V11H8V9ZM4 13H8V15H4V13ZM8 17H4V19H8V17ZM4 21H8V23H4V21ZM8 25H4V27H8V25ZM10 5H14V7H10V5ZM14 9H10V11H14V9ZM10 13H14V15H10V13ZM14 17H10V19H14V17ZM10 21H14V23H10V21ZM14 25H10V27H14V25ZM16 5H20V7H16V5ZM20 9H16V11H20V9ZM16 13H20V15H16V13ZM20 17H16V19H20V17ZM16 21H20V23H16V21ZM20 25H16V27H20V25Z"
      fill="#2E6680"
    />
  </svg>
);

export default IconFile;
