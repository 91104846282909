const IconEllipse: React.FC<React.SVGProps<SVGSVGElement>> = ({
  stroke,
  strokeWidth,
}) => (
  <svg
    data-testid="ellipse-icon"
    width="22"
    height="22"
    viewBox="0 0 22 22"
    stroke={stroke ?? '#979797'}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="11" cy="11" r="10" fill="none" strokeWidth={strokeWidth} />
  </svg>
);

export default IconEllipse;
