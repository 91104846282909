import { MappedFields } from 'types';
import { mapImportFileType } from 'helpers/mapImportFileType';
import api from './api';
import { ImportFileType, ImportStep } from '../enums';

interface AccountStatusChange {
  accountId: number;
  step: number;
  mappedFields: MappedFields;
  importFileType: ImportFileType;
}

export const formatStatus = (
  accountId: number,
  step: ImportStep,
  mappedFields: MappedFields,
  importFileTypeString: string
) => ({
  accountId,
  step,
  mappedFields,
  importFileType: mapImportFileType(importFileTypeString),
});

export async function changeAccountStatus(status: AccountStatusChange) {
  return api.patch('/account/importstatus', status).then(({ data }) => data);
}
