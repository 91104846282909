import { HeaderPortalId } from '../../portals/HeaderContentPortal';
import { ImportSteps } from '../DataImportSteps/ImportSteps';
import style from './ImportStatus.module.scss';

export const ImportStatus = () => (
  <div className={style.importStatus}>
    <ImportSteps />
    <div id={HeaderPortalId} />
  </div>
);
