import axios from 'axios';

const devURL = 'https://localhost:5001';
const stgURL = 'https://stg-import-backend.servicebridge.com';
const prodURL = 'https://prd-import-backend.servicebridge.com';

export const getBaseURL = () => {
  const currentURL = window.location.href;
  if (currentURL.includes('local')) {
    return devURL;
  }
  if (currentURL.includes('stg-import')) {
    return stgURL;
  }
  return prodURL;
};

export default axios.create({
  baseURL: getBaseURL(),
  withCredentials: true,
});
