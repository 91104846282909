import { Col, Spin, Row, Space } from 'antd';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setStepStatus } from 'reducers/DataImportSlice';
import IconClose from 'components/Icons/IconClose';
import IconFile from 'components/Icons/IconFile';
import useFileName from 'hooks/useFileName';
import { SelectedFileProps } from '.';
import { ImportStep } from '../../../enums';
import style from './SelectedFile.module.scss';

export const SelectedFile: React.FC<SelectedFileProps> = ({
  onRemove,
  isLoading,
}) => {
  const fileName = useFileName();
  const dispatch = useDispatch();

  useEffect(() => {
    if (fileName) {
      dispatch(
        setStepStatus({ id: ImportStep.FileUpload, stepFinished: true })
      );
    }
  }, [dispatch, fileName]);

  return (
    <div className={style.selectedFileBox}>
      <Row wrap={false} align="middle" gutter={16}>
        <Col flex="auto">
          <Row wrap={false} align="middle" gutter={16}>
            <Col>
              <IconFile />
            </Col>
            <Col>
              <Space direction="vertical" align="start">
                <div className={style.selectedFileLabel}>Selected file</div>
                <div>{fileName}</div>
              </Space>
            </Col>
          </Row>
        </Col>
        <Col
          onClick={onRemove}
          onKeyDown={onRemove}
          data-testid="file-remove"
          tabIndex={0}
        >
          {isLoading ? <Spin /> : <IconClose />}
        </Col>
      </Row>
    </div>
  );
};
