import { Select } from 'antd';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import useRequiredPropertiesMatched from 'hooks/useRequiredPropertiesMatched';
import useAllProperties from 'hooks/useAllProperties';
import useShouldImmportColumnHeader from 'hooks/useShouldImmportColumnHeader';
import {
  matchProperty,
  setDoNotImport,
  setPreviewState,
  setStepStatus,
  setValidationResult,
} from 'reducers/DataImportSlice';
import { ImportStep } from 'enums';
import usePreviewState from 'hooks/usePreviewState';
import { PropertyDropdownProps } from '.';
import style from './PropertyDropdown.module.scss';
import './PropertyDropdown.scss';
import { option } from './PropertyDropdownOption';

const { Option } = Select;

const optionsHeader = (value: string) => (
  <Option
    value={`${value} properties`}
    disabled
    className="dropdownHeader propertyOption"
  >
    {`${value} properties`}
  </Option>
);

export const PropertyDropdown: React.FC<PropertyDropdownProps> = ({
  column,
}) => {
  const properties = useAllProperties();
  const dispatch = useDispatch();
  const isPreviewReady = usePreviewState();
  const doNotImportColumnHeader = useShouldImmportColumnHeader(column);
  const allRequiredPropertiesMatched = useRequiredPropertiesMatched();

  const sortedProperties = [...properties].sort((a, b) =>
    a.displayName.localeCompare(b.displayName)
  );
  const index = sortedProperties.findIndex(
    (item) => item.matchedHeader === column.columnHeader
  );

  const resetPreviewStep = () => {
    dispatch(setValidationResult([]));
    dispatch(setStepStatus({ id: ImportStep.Preview, stepFinished: false }));
    dispatch(setPreviewState(false));
  };

  const onPropertySelect = (value: string) => {
    if (value === 'Do not import') {
      dispatch(setDoNotImport({ ...column, doNotImport: true }));
    } else {
      dispatch(
        matchProperty({
          value,
          matchedHeader: column.columnHeader,
        })
      );
    }
    if (isPreviewReady) resetPreviewStep();
  };

  useEffect(() => {
    if (allRequiredPropertiesMatched) {
      dispatch(
        setStepStatus({ id: ImportStep.FieldMapping, stepFinished: true })
      );
    } else {
      dispatch(
        setStepStatus({ id: ImportStep.FieldMapping, stepFinished: false })
      );
    }
  }, [dispatch, allRequiredPropertiesMatched]);

  const onPropertyClear = () => {
    if (sortedProperties[index]?.displayName) {
      dispatch(
        matchProperty({
          value: sortedProperties[index]?.displayName,
          matchedHeader: '',
        })
      );
    }
    dispatch(setDoNotImport({ ...column, doNotImport: false }));
    if (isPreviewReady) resetPreviewStep();
  };

  const requiredProperties = sortedProperties.filter(
    (property) => property.isRequired && !property.matchedHeader
  );
  const optionalProperties = sortedProperties.filter(
    (property) => !property.isRequired && !property.matchedHeader
  );

  return (
    <Select
      allowClear
      showSearch
      placeholder="Choose Option"
      className={style.propertyDropdown}
      onSelect={onPropertySelect}
      onClear={onPropertyClear}
      onChange={onPropertyClear}
      value={
        doNotImportColumnHeader
          ? 'Do not import'
          : sortedProperties[index]?.displayName
      }
      optionLabelProp="label"
      listHeight={500}
    >
      {requiredProperties.length > 0 && optionsHeader('Required')}
      {requiredProperties.map((item) => option(item))}
      {optionalProperties.length > 0 && optionsHeader('Optional')}
      {optionalProperties.map((item) => option(item))}
      <Option
        value="Do not import"
        label="Do not import"
        className="bottomOption"
      >
        Don&apos;t import column
      </Option>
    </Select>
  );
};
