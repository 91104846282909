import { forwardRef } from 'react';
import { FooterNotificationProps } from '.';
import style from './FooterNotification.module.scss';

export const FooterNotification = forwardRef<
  HTMLDivElement,
  FooterNotificationProps
>((props, ref) => {
  const { className, ...children } = props;

  const classNames = [style.notification, ...(className?.split(' ') ?? [])]
    .filter(Boolean)
    .join(' ');

  return <div ref={ref} {...children} className={classNames} />;
});

FooterNotification.displayName = 'FooterNotification';
