import { Button, Modal } from 'antd';
import { CancelConfirmationProps } from '.';

export const CancelConfirmationModal: React.FC<CancelConfirmationProps> = ({
  showModal,
  handleModalCancel,
  handleConfirm,
}) => (
  <Modal
    title="Confirm cancel"
    visible={showModal}
    onCancel={handleModalCancel}
    footer={[
      <Button key="back" onClick={handleModalCancel}>
        No
      </Button>,
      <Button type="primary" key="submit" onClick={handleConfirm}>
        Yes
      </Button>,
    ]}
  >
    Are you sure you want to leave? All work will be lost.
  </Modal>
);
