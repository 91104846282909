import { Button, Space } from 'antd';
import { useDispatch } from 'react-redux';
import { Paper } from 'components/Paper';
import {
  clearFollowingSteps,
  selectType,
  setColumns,
  setProperties,
  setUploadedFile,
  stepMove,
} from 'reducers/DataImportSlice';
import useDataType from 'hooks/useDataType';
import useCurrentStep from 'hooks/useCurrentStep';
import { updateUserStatusImportType } from 'api/updateUserStatusImportType';
import { ErrorType, ImportFileType } from 'enums';
import useAccountId from 'hooks/useAccountId';
import { changeImportStatus } from 'api/changeImportStatus';
import { Notification } from 'components/Notification';
import { genericError } from 'helpers/errorMessages';
import useEffectOnce from 'hooks/useEffectOnce';
import style from './TypeSelector.module.scss';
import { DataTypeDropDown } from '../DataTypeDropdown';

type TypeSelectorProps = {
  types: string[];
};

export const TypeSelector = ({ types }: TypeSelectorProps) => {
  const dispatch = useDispatch();
  const selectedDataType = useDataType();
  const currentStepId = useCurrentStep().id as number;
  const accountId = useAccountId();

  const handleSelect = async (value: string) => {
    if (value !== selectedDataType) {
      try {
        await changeImportStatus({
          accountId,
          status: 0,
          step: 0,
          fileName: '',
          fileUrl: '',
          mappedFields: {},
          importFileType: ImportFileType.None,
        });
        dispatch(clearFollowingSteps(currentStepId));
        dispatch(setUploadedFile({ fileName: '', fileUploaded: false }));
        dispatch(setColumns([]));
        dispatch(setProperties([]));
      } catch (e) {
        Notification(ErrorType.GeneralError, {
          message: 'Error',
          description: genericError,
        });
      }
    }

    dispatch(selectType(value));
  };

  const handleDeselect = () => {
    dispatch(selectType(''));
  };

  const handleStart = () => {
    saveImportType();
    dispatch(stepMove(currentStepId + 1));
  };

  const saveImportType = async () => {
    const dataType = Object.entries(ImportFileType).find(
      ([, type]) => type === selectedDataType
    )?.[0] as ImportFileType;
    try {
      await updateUserStatusImportType(dataType.toString());
    } catch (e) {
      Notification(ErrorType.GeneralError, {
        message: 'Error',
        description: genericError,
      });
    }
  };

  useEffectOnce(() => {
    const dataType = window.location.search?.split('=')[1];
    if (dataType) {
      handleSelect(ImportFileType[dataType as keyof typeof ImportFileType]);
    }
  });

  return (
    <Paper className={style.typeSelector}>
      <div className={style.typeSelectorContent}>
        <Space direction="vertical" size="large">
          <h2 className={style.typeSelectorHeader}>
            Let&apos;s import your data
          </h2>
          <h3 className={style.typeSelectorHeaderSecondary}>
            Select what you would like to import
          </h3>
          <DataTypeDropDown
            onTypeSelect={handleSelect}
            onDeselect={handleDeselect}
            data={types}
          />
          <Button
            type="primary"
            disabled={!selectedDataType}
            onClick={handleStart}
          >
            Start
          </Button>
        </Space>
      </div>
    </Paper>
  );
};
