import { createPortal } from 'react-dom';

export const FooterPortalId = 'footer-portal';

interface FooterContentPortalProps {
  children?: React.ReactNode;
}

export const FooterContentPortal: React.FC<FooterContentPortalProps> = ({
  children,
}) => {
  const footerElement = document.getElementById(FooterPortalId);

  if (!footerElement) return null;

  return createPortal(children, footerElement);
};
