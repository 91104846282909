import api from 'api/api';
import { changeImportStatus } from 'api/changeImportStatus';
import { Notification } from 'components/Notification';
import { ErrorType, ImportFileType } from 'enums';
import { CancelConfirmationModal } from 'features/CancelConfirmationModal';
import { genericError } from 'helpers/errorMessages';
import useAccountId from 'hooks/useAccountId';
import useCurrentStep from 'hooks/useCurrentStep';
import { useState } from 'react';
import { HeaderProps } from '.';
import style from './Header.module.scss';

export const Header: React.FC<HeaderProps> = (props) => {
  const { userName } = props;
  const [showCancelConfirmation, setShowCancelConfirmation] = useState(false);
  const accountId = useAccountId();
  const currentStep = useCurrentStep();

  const redirectToHome = () => {
    window.location.href = `${api.defaults.baseURL}/navigation/navigateto/fsm`;
  };

  const handleLogoClick = () => {
    if (currentStep.id === 0) redirectToHome();
    else setShowCancelConfirmation(true);
  };

  const hideCancelConfirmation = () => setShowCancelConfirmation(false);

  const clearState = async () => {
    try {
      await changeImportStatus({
        accountId,
        status: 0,
        step: 0,
        fileName: '',
        fileUrl: '',
        mappedFields: {},
        importFileType: ImportFileType.None,
      });
      redirectToHome();
    } catch (e) {
      Notification(ErrorType.GeneralError, {
        message: 'Error',
        description: genericError,
      });
    }
  };

  return (
    <header className={style.header}>
      <div className={style.headerContent}>
        <div
          role="button"
          onClick={handleLogoClick}
          onKeyDown={handleLogoClick}
          className={style.logo}
          tabIndex={0}
        >
          <span>GPS Insight</span>
        </div>
        <div className={style.userName}>{userName}</div>
      </div>
      <CancelConfirmationModal
        showModal={showCancelConfirmation}
        handleModalCancel={hideCancelConfirmation}
        handleConfirm={clearState}
      />
    </header>
  );
};
