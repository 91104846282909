import { useSelector } from 'react-redux';
import { DATA_IMPORT_SLICE } from 'reducers/DataImportSlice';
import { Column, RootState } from '../types';

const useShouldImmportColumnHeader = (column: Column) =>
  useSelector(
    (state: RootState) =>
      state[DATA_IMPORT_SLICE].columns.find(
        (item) => column.columnHeader === item.columnHeader
      )?.doNotImport
  );

export default useShouldImmportColumnHeader;
