import { Popover } from 'antd';
import { StrictMode, useRef, useState } from 'react';
import { v4 } from 'uuid';
import { TableCellProps } from '.';
import style from './TableCell.module.scss';

export const TableCell: React.FC<TableCellProps> = ({
  record,
  item,
  index,
}) => {
  const [showPopover, setShowPopover] = useState(false);

  const classNames = ['cellInner', index === 0 && 'firstCell']
    .filter(Boolean)
    .join(' ');

  const columnData = () => {
    if (typeof item !== 'string') {
      return record?.columns?.find((column) => column.name === item.title);
    }
    return null;
  };

  const errorMessages = columnData()?.errors;

  const displayPopover = errorMessages && errorMessages.length > 0;

  const content = columnData()?.value ?? (item as React.ReactNode);

  const cellRef = useRef<HTMLDivElement>(null);

  const handleMouseEvents = (mouseLeave?: boolean) => {
    if (mouseLeave && showPopover) {
      setShowPopover(false);
    } else {
      const cellContentLong =
        cellRef.current && cellRef.current.offsetHeight > 44;

      if (cellContentLong || displayPopover) {
        setShowPopover(true);
      }
    }
  };

  return (
    <div
      onMouseOver={() => handleMouseEvents()}
      onFocus={() => handleMouseEvents()}
      onMouseLeave={() => handleMouseEvents(true)}
      className={classNames}
    >
      <div ref={cellRef} className={style.cellText}>
        {content || '\xa0'}
      </div>
      <StrictMode>
        <Popover
          key={v4()}
          content={
            displayPopover && errorMessages
              ? errorContainer(errorMessages)
              : content
          }
          visible={showPopover}
          overlayStyle={{ maxWidth: '400px' }}
        />
      </StrictMode>
    </div>
  );
};

const errorContainer = (errorMessages: string[]) => (
  <>
    <div className={style.errorHeader}>
      This field contains {errorMessages.length > 1 ? 'errors' : 'an error'}
    </div>
    {errorMessages.map((error) => (
      <p key={v4()}>{error}</p>
    ))}
  </>
);
