export enum RowImportStatus {
  Create = 'Create',
  Conflict = 'Conflict',
  Update = 'Update',
  CreateAndUpdate = 'Create & Update',
}

export enum ImportStep {
  None = 0,
  FileUpload = 1,
  FieldMapping = 2,
  Preview = 3,
  Finish = 4,
}

export enum ImportStepName {
  None = 'None',
  FileUpload = 'File Upload',
  FieldMapping = 'Field Mapping',
  Preview = 'Preview',
  Finish = 'Finish',
}

export enum ImportStatus {
  None = 0,
  InProgress = 1,
  Error = 2,
}

export enum ImportStatusName {
  None = 'None',
  InProgress = 'InProgress',
  Error = 'Error',
}

export enum ImportFileType {
  Customer = 'Customers',
  Part = 'Products & Services',
  None = 'None',
}

export enum ErrorType {
  None = 0,
  Warning = 1,
  GeneralError = 2,
  SystemError = 3,
}
