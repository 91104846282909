import { PreviewData } from 'types';

export const populatePreviewColumnsData = (data: PreviewData[]) => {
  const columnNames: string[] = [];

  data.forEach((item) => {
    item.columns.forEach((column) => {
      if (!columnNames.includes(column.name)) columnNames.push(column.name);
    });
  });

  const columnData = columnNames.map((item) => ({
    title: item,
    dataIndex: item,
  }));

  return columnData;
};
