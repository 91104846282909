import { createPortal } from 'react-dom';

export const HeaderPortalId = 'header-portal';

interface HeaderContentPortalProps {
  children?: React.ReactNode;
}

export const HeaderContentPortal: React.FC<HeaderContentPortalProps> = ({
  children,
}) => {
  const headerElement = document.getElementById(HeaderPortalId);

  if (!headerElement) return null;

  return createPortal(children, headerElement);
};
