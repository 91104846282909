import { ColumnsType } from 'antd/es/table';
import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { DataTable } from 'components/DataTable';
import { TableCell } from 'components/TableCell';
import IconSmallEllipse from 'components/Icons/IconSmallEllipse';
import { Spin } from 'components/Spin';
import { PreviewData } from 'types';
import {
  setRowsToImport,
  setStepStatus,
  setValidationResult,
} from 'reducers/DataImportSlice';
import { getValidationResult } from 'api/getValidationResult';
import useValidationResult from 'hooks/useValidationResult';
import { countRowsWithError } from 'helpers/countRowsWithErrors';
import { populatePreviewColumnsData } from 'helpers/populatePreviewColumnsData';
import { Notification } from 'components/Notification';
import { genericError } from 'helpers/errorMessages';
import { ImportStep, RowImportStatus, ErrorType } from '../../../enums';
import './ImportPreviewTable.scss';
import style from './ImportPreviewTable.module.scss';

export const ImportPreviewTable = () => {
  const validationResult = useValidationResult();
  const rowsWithError = countRowsWithError(validationResult);
  const numberOfRows = validationResult.length;
  const dispatch = useDispatch();

  const fetchValidationResult = useCallback(async () => {
    try {
      const { error, errorType, rows } = await getValidationResult();
      if (
        errorType === ErrorType.GeneralError ||
        errorType === ErrorType.SystemError
      ) {
        Notification(errorType, {
          message: 'Error',
          description: error,
        });
      } else if (errorType === ErrorType.Warning) {
        Notification(errorType, {
          message: 'Warning',
          description: error,
        });
      }
      dispatch(setValidationResult(rows));
    } catch (e) {
      Notification(ErrorType.GeneralError, {
        message: 'Error',
        description: genericError,
      });
    }
  }, [dispatch]);

  useEffect(() => {
    fetchValidationResult();
  }, [fetchValidationResult]);

  useEffect(() => {
    dispatch(setRowsToImport({ numberOfRows, rowsWithError }));
    dispatch(
      setStepStatus({
        id: ImportStep.Preview,
        stepFinished: !rowsWithError,
      })
    );
  }, [numberOfRows, rowsWithError, dispatch]);

  const columnsCellRender = populatePreviewColumnsData(validationResult).map(
    (item, index) => ({
      ...item,
      render: (text: any, record: PreviewData) => {
        const column = record.columns.find((col) => {
          if (col.name === item.title) {
            return col;
          }
          return null;
        });
        return {
          props: {
            className: column && column.errors.length > 0 ? 'errorCell' : '',
            width: 150,
          },
          children: <TableCell record={record} item={item} index={index} />,
        };
      },
    })
  );

  const columns: ColumnsType<PreviewData> = [
    {
      title: 'IMPORT BY',
      dataIndex: 'rowStatus',
      key: 'rowStatus',
      fixed: 'left',
      width: 110,
      render: (text: any, record: PreviewData) =>
        record.rowStatus === RowImportStatus.Conflict ? (
          <div className={style.conflictCell}>
            <IconSmallEllipse />
            <span className={style.conflictCellContent}>{text}</span>
          </div>
        ) : (
          <span>{text}</span>
        ),
    },
    ...columnsCellRender,
  ];

  if (validationResult.length <= 0) {
    return <Spin />;
  }

  return (
    <DataTable
      data={validationResult}
      headerColumns={columns}
      scroll={{ x: 'max-content' }}
      className="importPreviewTable"
    />
  );
};
