import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { importSteps } from 'features/DataImportSteps/ImportSteps/stepsData';
import {
  Property,
  Steps,
  File,
  PropertyPayload,
  Column,
  InitialState,
  RowsToImport,
  AvailableProperty,
  PreviewData,
} from 'types';

export const DATA_IMPORT_SLICE = 'Import';

export const initialState: InitialState = {
  dataType: '',
  steps: importSteps,
  status: 0,
  currentStepId: 0,
  uploadedFile: {
    fileName: '',
    fileUploaded: false,
  } as File,
  columns: [],
  properties: [],
  validationResult: [],
  softMatchDone: false,
  previewReady: false,
  rowsToImport: 0,
  rowsWithError: 0,
  accountId: 0,
};

export const dataImportSlice = (mockState?: InitialState) =>
  createSlice({
    name: DATA_IMPORT_SLICE,
    initialState: mockState ?? initialState,
    reducers: {
      selectType: (state, { payload }: PayloadAction<string>) => {
        state.dataType = payload;
      },
      setStepStatus: (state, { payload }: PayloadAction<Steps>) => {
        const index = state.steps.findIndex(
          (step: Steps) => step.id === payload.id
        );
        if (index !== -1) {
          state.steps[index].stepFinished = payload.stepFinished;
        }
      },
      setStatus: (state, { payload }: PayloadAction<number>) => {
        state.status = payload;
      },
      stepMove: (state, { payload }: PayloadAction<number>) => {
        state.currentStepId = payload;
      },
      setUploadedFile: (state, { payload }: PayloadAction<File>) => {
        state.uploadedFile.fileName = payload.fileName;
        state.uploadedFile.fileUploaded = payload.fileUploaded;
      },
      matchProperty: (state, { payload }: PayloadAction<PropertyPayload>) => {
        const key = state.properties.findIndex(
          (property: Property) => property.displayName === payload.value
        );
        if (key !== -1) {
          state.properties[key].matchedHeader = payload.matchedHeader;
        }
      },
      setProperties: (
        state,
        { payload }: PayloadAction<AvailableProperty[]>
      ) => {
        state.properties = payload;
      },
      setValidationResult: (
        state,
        { payload }: PayloadAction<PreviewData[]>
      ) => {
        state.validationResult = payload;
      },
      setColumns: (state, { payload }: PayloadAction<Column[]>) => {
        state.columns = payload;
      },
      setSoftMatch: (state, { payload }: PayloadAction<boolean>) => {
        state.softMatchDone = payload;
      },
      setDoNotImport: (state, { payload }: PayloadAction<Column>) => {
        const key = state.columns.findIndex(
          (column: Column) => column.columnHeader === payload.columnHeader
        );
        if (key !== -1) {
          state.columns[key].doNotImport = payload.doNotImport;
        }
      },
      setPreviewState: (state, { payload }: PayloadAction<boolean>) => {
        state.previewReady = payload;
      },
      setRowsToImport: (state, { payload }: PayloadAction<RowsToImport>) => {
        state.rowsToImport = payload.numberOfRows - payload.rowsWithError;
        state.rowsWithError = payload.rowsWithError;
      },
      setInitialState: (state) => {
        state = initialState;
        return state;
      },
      clearFollowingSteps: (state, { payload }: PayloadAction<number>) => {
        state.steps.forEach((step) => {
          if (step.id >= payload) {
            step.stepFinished = false;
          }
        });
      },
      setAccountId: (state, { payload }: PayloadAction<number>) => {
        state.accountId = payload;
      },
    },
  });

export const {
  selectType,
  setStepStatus,
  setStatus,
  setUploadedFile,
  stepMove,
  matchProperty,
  setProperties,
  setValidationResult,
  setSoftMatch,
  setDoNotImport,
  setPreviewState,
  setRowsToImport,
  setInitialState,
  clearFollowingSteps,
  setAccountId,
  setColumns,
} = dataImportSlice().actions;

export default dataImportSlice().reducer;
