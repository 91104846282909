import useDataType from 'hooks/useDataType';
import style from './MappingTableInfo.module.scss';

export const MappingTableInfo = () => (
  <div className={style.MappingTableInfo} data-testid="map-info">
    <h4>Map columns in your file to contact properties</h4>
    <p className={style.MappingTableInfoContent}>
      {`Each column header below should be mapped to a
      ${useDataType()}
      property in GPS Insight.
      Unmapped Columns will not be imported.`}
    </p>
  </div>
);
