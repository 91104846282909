import { Select } from 'antd';
import useDataType from 'hooks/useDataType';
import { IDataTypeDropdownProps } from '.';
import style from './DataTypeDropdown.module.scss';

export const DataTypeDropDown: React.FC<IDataTypeDropdownProps> = ({
  onTypeSelect,
  onDeselect,
  data,
}) => {
  const options: Array<{ value: string }> = [];

  data.forEach((option) => {
    options.push({ value: option });
  });

  const selectedDataType = useDataType();

  return (
    <Select
      options={options}
      showSearch
      allowClear
      placeholder="Select one"
      className={style.typeSelector}
      onSelect={onTypeSelect}
      onClear={onDeselect}
      data-testid="dataTypeSelector"
      value={selectedDataType !== '' ? selectedDataType : undefined}
    />
  );
};
