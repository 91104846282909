import { Space } from 'antd';
import IconCheckmark from 'components/Icons/IconCheckmark';
import IconEllipse from 'components/Icons/IconEllipse';
import IconLine from 'components/Icons/IconLine';
import useCurrentStep from 'hooks/useCurrentStep';
import useStep from 'hooks/useStep';
import { StepProps } from '.';
import style from './Step.module.scss';
import './StepSvg.scss';

export const Step: React.FC<StepProps> = (props) => {
  const { showLine, stepName, className } = props;

  const currentStep = useCurrentStep();
  const getStepByName = useStep(stepName);

  const classNames = [
    currentStep.stepName === stepName && style.currentStep,
    getStepByName?.stepName === stepName &&
      getStepByName.stepFinished &&
      style.stepFinished,
    ...(className?.split(' ') ?? []),
  ]
    .filter(Boolean)
    .join(' ');

  return (
    <Space
      direction="vertical"
      className={classNames}
      data-testid="import-step"
    >
      <div className={style.stepIcon}>
        {getStepByName?.stepName === stepName && getStepByName?.stepFinished ? (
          <IconCheckmark />
        ) : (
          <IconEllipse
            strokeWidth={currentStep.stepName === stepName ? '2' : '1'}
          />
        )}
        {showLine && <IconLine className="stepLine" name={stepName} />}
      </div>
      <div className={style.stepName}>{stepName}</div>
    </Space>
  );
};
