import { Alert } from 'antd';
import IconWarning from 'components/Icons/IconWarning';
import useRowsWithError from 'hooks/useRowsWithError';
import style from './ImportAlert.module.scss';
import './ImportAlert.scss';

export const ImportAlert = () => {
  const rowsWithErrorCount = useRowsWithError();
  const alertMessage = () => (
    <div className={style.alertMessage}>
      <strong>{rowsWithErrorCount}</strong>
      {rowsWithErrorCount > 1 ? (
        <span>
          <strong> Rows </strong>
          contain
        </span>
      ) : (
        <span>
          <strong> Row </strong>
          contains
        </span>
      )}
      <span>
        {' '}
        errors, please check the source file and start the upload process again.
      </span>
    </div>
  );

  return (
    <Alert
      className="importAlert"
      message={alertMessage()}
      type="error"
      showIcon
      icon={<IconWarning />}
    />
  );
};
