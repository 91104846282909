import { useSelector } from 'react-redux';
import { DATA_IMPORT_SLICE } from 'reducers/DataImportSlice';
import { RootState } from '../types';

const useCurrentStep = () =>
  useSelector((state: RootState) => {
    const currentStep = state[DATA_IMPORT_SLICE].steps.find(
      (step) => step.id === state[DATA_IMPORT_SLICE].currentStepId
    );
    if (currentStep) return currentStep;
    return {
      id: 0,
      stepName: 'None',
      stepFinished: false,
    };
  });

export default useCurrentStep;
