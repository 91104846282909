import { getBaseURL } from './api';

export async function getUserProfile() {
  return fetch(`${getBaseURL()}/auth/getuserprofile`, {
    method: 'GET',
    redirect: 'manual',
    credentials: 'include',
  })
    .then((res) =>
      res.json().then((data) => ({
        data,
        status: res.status,
      }))
    )
    .then((data) => data);
}
