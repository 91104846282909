import useStepById from 'hooks/useStepById';
import usePreviousStep from 'hooks/usePreviousStep';
import useStep from 'hooks/useStep';

const IconLine: React.FC<React.SVGProps<SVGSVGElement>> = ({
  className,
  name,
}) => {
  const thisStep = useStep(name as string);
  const previousStep = usePreviousStep();
  const nextStep = useStepById(thisStep!.id + 1);
  const isThisStepFinished = thisStep?.stepFinished && nextStep?.stepFinished;

  return (
    <svg width="100%" viewBox="0 0 100 1" className={className}>
      <path className="path" stroke="#A3A3A3" strokeWidth="1" d="M0 0 H100" />
      <path
        stroke={isThisStepFinished ? '#0A5C91' : '#A3A3A3'}
        strokeWidth="1"
        d="M0 0 H100"
      />
      {thisStep === previousStep && (
        <path className="path" stroke="#0A5C91" strokeWidth="1" d="M0 0 H100" />
      )}
    </svg>
  );
};

export default IconLine;
