/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Button, message, Upload, Spin } from 'antd';
import { RcFile } from 'antd/lib/upload';
import { changeImportStatus } from 'api/changeImportStatus';
import { downloadSampleFile } from 'api/downloadSampleFile';
import { getImportStatus } from 'api/getImportStatus';
import { uploadFile } from 'api/uploadFile';
import { Notification } from 'components/Notification';
import { ErrorType, ImportFileType, ImportStep } from 'enums';
import { genericError } from 'helpers/errorMessages';
import useAccountId from 'hooks/useAccountId';
import useDataType from 'hooks/useDataType';
import useFileUploaded from 'hooks/useFileUploaded';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  clearFollowingSteps,
  setColumns,
  setProperties,
  setSoftMatch,
  setStepStatus,
  setUploadedFile,
} from 'reducers/DataImportSlice';
import { SelectedFile } from '../SelectedFile';
import style from './FileUploader.module.scss';

const acceptedFileTypes = ['.xls', '.xlsx'];

export const FileUploader = () => {
  const { Dragger } = Upload;
  const uploadDone = useFileUploaded();
  const [typeIsValid, setTypeIsValid] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const accountId = useAccountId();
  const dispatch = useDispatch();
  const dataType = useDataType();
  const maximumFileSize = 100000000;

  const checkFileType = (file: RcFile) => {
    const fileType = acceptedFileTypes.filter((type) =>
      file.name.endsWith(type)
    );
    if (!fileType.length) {
      message.error(`${file.name} type is not valid`);
      setTypeIsValid(false);
    } else {
      setTypeIsValid(true);
    }
    return false;
  };

  const handleSelect = async (fileProps: any) => {
    const data = await getImportStatus();
    const { file, fileList } = fileProps;

    if (file.size > maximumFileSize) {
      fileList.shift();
      Notification(ErrorType.GeneralError, {
        message: 'Error',
        description: 'File must be smaller than 100MB.',
      });
      return;
    }

    if (data.currentUserStatus.step > 0) {
      Notification(ErrorType.Warning, {
        message: 'Warning',
        description: (
          <div>
            <p>Another file is being uploaded. Please try again.</p>
            <Button onClick={() => window.location.reload()}>Refresh</Button>
          </div>
        ),
      });
      fileList.shift();
      return;
    }

    if (!typeIsValid) {
      fileList.shift();
      return;
    }

    if (fileList.length > 1) fileList.shift();

    const formData = new FormData();
    formData.append('file', fileList[0].originFileObj);

    try {
      setIsLoading(true);
      await uploadFile(formData);
      dispatch(
        setUploadedFile({ fileName: fileList[0].name, fileUploaded: true })
      );
      dispatch(
        setStepStatus({ id: ImportStep.FileUpload, stepFinished: true })
      );
      dispatch(setSoftMatch(false));
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      // @ts-ignore
      if (e?.response?.data?.error) {
        Notification(ErrorType.GeneralError, {
          message: 'Error',
          // @ts-ignore
          description: e.response.data.error,
        });
      } else {
        Notification(ErrorType.GeneralError, {
          message: 'Error',
          description: genericError,
        });
      }
    }
  };

  const handleClick = async () => {
    const realDataType = Object.entries(ImportFileType).find(
      ([, type]) => type === dataType
    )?.[0] as string;
    const { data, headers } = await downloadSampleFile(realDataType);
    const contentType = headers['content-type'];
    const fileName = headers['content-disposition']
      .split('filename=')[1]
      .split(';')[0];

    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(
      new Blob([data as any], { type: contentType })
    );
    link.download = fileName;
    link.click();
    link.remove();
  };

  const handleRemove = async () => {
    try {
      setIsLoading(true);
      await changeImportStatus({
        accountId,
        status: 0,
        step: 0,
        fileName: '',
        fileUrl: '',
        mappedFields: {},
        importFileType: dataType,
      });
      dispatch(clearFollowingSteps(ImportStep.FileUpload));
      dispatch(setUploadedFile({ fileName: '', fileUploaded: false }));
      dispatch(setColumns([]));
      dispatch(setProperties([]));
      setIsLoading(false);
    } catch (e) {
      Notification(ErrorType.GeneralError, {
        message: 'Error',
        description: genericError,
      });
    }
  };

  return (
    <>
      <Dragger
        name="file"
        multiple={false}
        accept={acceptedFileTypes.join()}
        showUploadList={false}
        onChange={handleSelect}
        beforeUpload={checkFileType}
        data-testid="uploader"
      >
        {!uploadDone && (
          <div className={style.fileUploader}>
            <h4 className={style.fileUploaderHeader}>Drop a file here or</h4>
            {!isLoading ? (
              <Button type="primary">Select file from your computer</Button>
            ) : (
              <Spin />
            )}
            <div className={style.fileUploaderFiletypes}>
              Only an Excel file can be used
            </div>
          </div>
        )}
      </Dragger>
      {uploadDone ? (
        <SelectedFile onRemove={handleRemove} isLoading={isLoading} />
      ) : (
        <Button
          ghost
          type="primary"
          className={style.downloadSample}
          onClick={handleClick}
        >
          Download a sample file
        </Button>
      )}
    </>
  );
};
