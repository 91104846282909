import { Table } from 'antd';
import { Column } from 'types';
import { DataTableProps } from '.';
import './DataTable.scss';

export const DataTable: React.FC<DataTableProps> = ({
  data,
  headerColumns,
  scroll,
  className,
}) => {
  const classNames = ['dataTable', ...(className?.split(' ') ?? [])]
    .filter(Boolean)
    .join(' ');

  return (
    <Table
      rowKey={(record: Column) => record.columnHeader ?? data.indexOf(record)}
      dataSource={data}
      columns={headerColumns}
      className={classNames}
      rowClassName={(record, index) =>
        index % 2 === 0 ? 'tableRowLight' : 'tableRowDark'
      }
      bordered
      pagination={data?.length < 99 ? false : undefined}
      scroll={scroll}
    />
  );
};
