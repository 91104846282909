import { changeAccountStatus, formatStatus } from 'api/changeAccountStatus';
import useAccountId from 'hooks/useAccountId';
import { useCallback, useEffect, useState } from 'react';
import useRowsWithError from 'hooks/useRowsWithError';
import usePreviewState from 'hooks/usePreviewState';
import { FooterContentPortal } from 'portals/FooterContentPortal';
import { HeaderContentPortal } from 'portals/HeaderContentPortal';
import useAllProperties from 'hooks/useAllProperties';
import { mapFields } from 'helpers/mapFields';
import { startValidateImport } from 'api/startValidateImport';
import useDataType from 'hooks/useDataType';
import { ErrorType, ImportStep } from 'enums';
import { Notification } from 'components/Notification';
import { genericError } from 'helpers/errorMessages';
import { ImportAlert } from './ImportAlert';
import { ImportPreviewTable } from './ImportPreviewTable';
import { ImportRowsAccept } from './ImportRowsAccept';
import { PreviewProgress } from './PreviewProgress';

export const ImportPreview = () => {
  const [validationStarted, setValidationStarted] = useState(false);
  const isPreviewReady = usePreviewState();
  const rowsWithErrorCount = useRowsWithError();
  const accountId = useAccountId();
  const properties = useAllProperties();
  const dataType = useDataType();

  const changeStatus = useCallback(async () => {
    try {
      await changeAccountStatus(
        formatStatus(
          accountId,
          ImportStep.Preview,
          mapFields(properties),
          dataType
        )
      );
      await startValidateImport();
      setValidationStarted(true);
    } catch (e) {
      Notification(ErrorType.GeneralError, {
        message: 'Error',
        description: genericError,
      });
    }
  }, [accountId, properties, dataType]);

  useEffect(() => {
    if (accountId && !isPreviewReady) changeStatus();
  }, [accountId, isPreviewReady, changeStatus]);

  if (!isPreviewReady) {
    return <PreviewProgress validationStarted={validationStarted} />;
  }

  return (
    <>
      {rowsWithErrorCount > 0 && (
        <HeaderContentPortal>
          <ImportAlert />
        </HeaderContentPortal>
      )}
      <FooterContentPortal>
        <ImportRowsAccept />
      </FooterContentPortal>
      <ImportPreviewTable />
    </>
  );
};
