const IconWarning: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.44444 0.5H5.23734L5.09089 0.646447L0.646447 5.09089L0.5 5.23734V5.44444V12.5556V12.7627L0.646447 12.9091L5.09089 17.3536L5.23734 17.5H5.44444H12.5556H12.7627L12.9091 17.3536L17.3536 12.9091L17.5 12.7627V12.5556V5.44444V5.23734L17.3536 5.09089L12.9091 0.646447L12.7627 0.5H12.5556H5.44444Z"
      fill="#FF4852"
      stroke="#E90000"
    />
    <rect x="7.5" y="3.5" width="3" height="6" fill="white" stroke="#F00013" />
    <rect x="7.5" y="11.5" width="3" height="3" fill="white" stroke="#F00013" />
  </svg>
);

export default IconWarning;
