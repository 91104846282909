import { getBaseURL } from './api';

export async function getImportTypes() {
  return fetch(`${getBaseURL()}/importtypes`, {
    method: 'GET',
    redirect: 'manual',
    credentials: 'include',
  })
    .then((res) => res.json().then((data) => data))
    .then((data) => data);
}
