import { RowImportStatus } from 'enums';
import { PreviewData } from 'types';

export const countRowsWithError = (data: PreviewData[] | null) => {
  let count = 0;
  data?.forEach((item) => {
    if (item.rowStatus === RowImportStatus.Conflict) count += 1;
  });
  return count;
};
