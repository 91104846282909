import { mapImportFileType } from 'helpers/mapImportFileType';
import api from './api';
import { ImportStatus, MappedFields } from '../types';

export interface ImportStatusChange {
  accountId: number;
  status: number;
  step?: number;
  fileName: string;
  fileUrl: string;
  mappedFields: MappedFields;
  importFileType: string;
}

export async function changeImportStatus(status: ImportStatusChange) {
  const formattedStatus: ImportStatusChange = {
    ...status,
    importFileType: mapImportFileType(status.importFileType),
  };

  return api
    .patch<ImportStatus>('/account/importstatus', formattedStatus)
    .then(({ data }) => data);
}
