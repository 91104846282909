import { FooterNotification } from 'components/FooterNotification';
import useRequiredPropertiesNotMatched from 'hooks/useNumberOfRequiredProperties';
import useSoftmatchState from 'hooks/useSoftMatchState';

export const RequiredFieldsNotification = () => {
  const notMatchedRequiredProperties = useRequiredPropertiesNotMatched();
  const isSoftMatchDone = useSoftmatchState();

  if (!isSoftMatchDone) return null;

  return (
    <FooterNotification>
      <div>
        <span>You have </span>
        {notMatchedRequiredProperties}
        <span> unmatched required </span>
        {notMatchedRequiredProperties > 1 ? (
          <span>fields.</span>
        ) : (
          <span>field.</span>
        )}
      </div>
    </FooterNotification>
  );
};
