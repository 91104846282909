import { Button, Space } from 'antd';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import useCurrentStep from 'hooks/useCurrentStep';
import { FooterPortalId } from 'portals/FooterContentPortal';
import { setInitialState, stepMove } from 'reducers/DataImportSlice';
import { ErrorType, ImportFileType, ImportStep } from 'enums';
import { changeImportStatus } from 'api/changeImportStatus';
import useAccountId from 'hooks/useAccountId';
import { Notification } from 'components/Notification';
import { genericError } from 'helpers/errorMessages';
import useValidationResult from 'hooks/useValidationResult';
import { CancelConfirmationModal } from '../CancelConfirmationModal';
import style from './Footer.module.scss';

export const Footer = () => {
  const [showCancelConfirmation, setShowCancelConfirmation] = useState(false);
  const dispatch = useDispatch();

  const currentStep = useCurrentStep();
  const currentStepId = currentStep.id as number;
  const accountId = useAccountId();
  const previewData = useValidationResult();

  const handleNext = () => dispatch(stepMove(currentStepId + 1));

  const handleBack = () => dispatch(stepMove(currentStepId - 1));

  const handleCancel = () => setShowCancelConfirmation(true);

  const hideCancelConfirmation = () => setShowCancelConfirmation(false);

  const clearState = async () => {
    try {
      await changeImportStatus({
        accountId,
        status: 0,
        step: 0,
        fileName: '',
        fileUrl: '',
        mappedFields: {},
        importFileType: ImportFileType.None,
      });
      dispatch(setInitialState());
    } catch (e) {
      Notification(ErrorType.GeneralError, {
        message: 'Error',
        description: genericError,
      });
    }
  };

  return (
    <footer className={style.siteFooter} data-testid="footer">
      <Button ghost className={style.footerBackBtn} onClick={handleBack}>
        <svg
          width="15"
          height="15"
          viewBox="0 0 17 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.06066 0.707107L7.35355 0L0 7.35355L7.35355 14.7071L8.06066 14L1.91418 7.85352H14.207V6.85352H1.91425L8.06066 0.707107Z"
          />
        </svg>
        Back
      </Button>
      <div id={FooterPortalId} className={style.footerContent} />
      <Space>
        <Button onClick={handleCancel}>Cancel</Button>
        <Button
          type="primary"
          disabled={
            (currentStepId === ImportStep.Preview &&
              previewData.length === 0) ||
            !currentStep.stepFinished
          }
          onClick={handleNext}
        >
          Next
        </Button>
      </Space>
      <CancelConfirmationModal
        showModal={showCancelConfirmation}
        handleModalCancel={hideCancelConfirmation}
        handleConfirm={clearState}
      />
    </footer>
  );
};
