import { DataTable } from 'components/DataTable';
import IconCheckmark from 'components/Icons/IconCheckmark';
import IconEllipse from 'components/Icons/IconEllipse';
import { Spin } from 'components/Spin';
import { getAvailableProperties } from 'api/getAvailableProperties';
import { getFilePreview } from 'api/getFilePreview';
import useAllColumns from 'hooks/useAllColumns';
import useRequiredPropertiesNotMatched from 'hooks/useNumberOfRequiredProperties';
import useSoftmatchState from 'hooks/useSoftMatchState';
import { FooterContentPortal } from 'portals/FooterContentPortal';
import { HeaderContentPortal } from 'portals/HeaderContentPortal';
import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  matchProperty,
  setColumns,
  setProperties,
  setSoftMatch,
  setStepStatus,
} from 'reducers/DataImportSlice';
import { Column } from 'types';
import { modifyString } from 'helpers/modifyString';
import useAllProperties from 'hooks/useAllProperties';
import { TableCell } from 'components/TableCell';
import { Notification } from 'components/Notification';
import { genericError } from 'helpers/errorMessages';
import { ErrorType, ImportStep } from 'enums';
import { ColumnsType } from 'antd/es/table';
import { MappingTableInfo } from '../MappingTableInfo';
import { PropertyDropdown } from '../PropertyDropdown';
import { RequiredFieldsNotification } from '../RequiredFieldsNotification';
import style from './MappingTable.module.scss';

export const MappingTable = () => {
  const columns = useAllColumns();
  const properties = useAllProperties();
  const unmatchedRequiredProps = useRequiredPropertiesNotMatched() > 0;
  const isSoftMatchDone = useSoftmatchState();
  const dispatch = useDispatch();

  const fetchAvailableProperties = useCallback(async () => {
    try {
      const data = await getAvailableProperties();
      dispatch(setProperties(data));
    } catch (e) {
      Notification(ErrorType.GeneralError, {
        message: 'Error',
        description: genericError,
      });
    }
  }, [dispatch]);

  const fetchFilePreview = useCallback(async () => {
    try {
      const preview = await getFilePreview();
      const adjustedPreview = preview.map((item) => ({
        columnHeader: item.columnHeader,
        doNotImport: item.doNotImport,
        value: item.value.join(', '),
      }));
      dispatch(setColumns(adjustedPreview));
    } catch (e) {
      Notification(ErrorType.GeneralError, {
        message: 'Error',
        description: genericError,
      });
    }
  }, [dispatch]);

  const softMatch = useCallback(() => {
    columns.forEach((item) => {
      const matchedProperty = properties.filter(
        (val) =>
          modifyString(val.displayName) === modifyString(item.columnHeader)
      );
      if (matchedProperty.length > 0) {
        dispatch(
          matchProperty({
            value: matchedProperty[0].displayName,
            matchedHeader: item.columnHeader,
          })
        );
      }
    });
  }, [columns, properties, dispatch]);

  const headerColumns: ColumnsType<Column> = [
    {
      title: 'Column Header from File',
      dataIndex: 'columnHeader',
      render: (text) => <span className={style.highlightedText}>{text}</span>,
    },
    {
      title: 'Preview',
      dataIndex: 'value',
      render: (text, record, index) => <TableCell item={text} index={index} />,
    },
    {
      title: 'GPS Insight Property',
      dataIndex: 'property',
      render: (text, record: Column) => <PropertyDropdown column={record} />,
      width: 345,
    },
    {
      title: 'Matched',
      render: (text, record: Column) => {
        const matched = properties.find(
          (property) => property.matchedHeader === record.columnHeader
        )?.matchedHeader && <IconCheckmark />;
        return record.doNotImport ? <IconEllipse stroke="#0A5C91" /> : matched;
      },
      width: 85,
      align: 'center',
    },
  ];

  useEffect(() => {
    if (!isSoftMatchDone) {
      fetchAvailableProperties();
      fetchFilePreview();
    }
  }, [isSoftMatchDone, fetchFilePreview, fetchAvailableProperties]);

  useEffect(() => {
    if (isSoftMatchDone && unmatchedRequiredProps) {
      dispatch(
        setStepStatus({ id: ImportStep.FieldMapping, stepFinished: false })
      );
    } else if (!isSoftMatchDone) {
      dispatch(
        setStepStatus({ id: ImportStep.FieldMapping, stepFinished: false })
      );
    } else {
      dispatch(
        setStepStatus({ id: ImportStep.FieldMapping, stepFinished: true })
      );
    }
  }, [isSoftMatchDone, unmatchedRequiredProps, dispatch]);

  useEffect(() => {
    if (!isSoftMatchDone && columns.length > 0 && properties.length > 0) {
      softMatch();
      dispatch(setSoftMatch(true));
    }
  }, [isSoftMatchDone, columns, properties, dispatch, softMatch]);

  return (
    <>
      <HeaderContentPortal>
        <MappingTableInfo />
      </HeaderContentPortal>
      {columns.length > 0 && isSoftMatchDone ? (
        <DataTable data={columns} headerColumns={headerColumns} />
      ) : (
        <Spin />
      )}
      {unmatchedRequiredProps && (
        <FooterContentPortal>
          <RequiredFieldsNotification />
        </FooterContentPortal>
      )}
    </>
  );
};
