import { useSelector } from 'react-redux';
import { DATA_IMPORT_SLICE } from '../reducers/DataImportSlice';
import { RootState } from '../types';

const useFileName = () =>
  useSelector(
    (state: RootState) => state[DATA_IMPORT_SLICE].uploadedFile.fileName
  );

export default useFileName;
