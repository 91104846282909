import { forwardRef } from 'react';
import { IPaperProps } from '.';
import styles from './Paper.module.scss';

export const Paper = forwardRef<HTMLDivElement, IPaperProps>((props, ref) => {
  const { className, ...children } = props;

  const classNames = [styles.paper, ...(className?.split(' ') ?? [])]
    .filter(Boolean)
    .join(' ');

  return <div ref={ref} {...children} className={classNames} />;
});

Paper.displayName = 'Paper';
