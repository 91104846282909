import { notification } from 'antd';
import { ArgsProps } from 'antd/lib/notification';
import { ErrorType } from 'enums';
import { defaultConfig } from '.';

export const Notification = (
  type: ErrorType,
  customConfig: ArgsProps,
  remove?: boolean
) => {
  notification.config(defaultConfig);

  switch (type) {
    case ErrorType.Warning:
      notification.warning(customConfig);
      break;

    case ErrorType.GeneralError:
    case ErrorType.SystemError:
      notification.error(customConfig);
      break;

    default:
      notification.open(customConfig);
      break;
  }

  if (remove) {
    notification.destroy();
  }
};
