import { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getUserProfile } from 'api/getUserProfile';
import { Spin } from 'components/Spin';
import {
  stepMove,
  setUploadedFile,
  setAccountId,
  setStepStatus,
  selectType,
  setPreviewState,
} from 'reducers/DataImportSlice';
import useCurrentStep from 'hooks/useCurrentStep';
import { getImportStatus } from 'api/getImportStatus';
import { getImportTypes } from 'api/getImportTypes';
import useAccountId from 'hooks/useAccountId';
import api from 'api/api';
import { Header } from 'features/Header';
import { getValidationProcess } from 'api/getValidationProcess';
import { Notification } from 'components/Notification';
import { genericError } from 'helpers/errorMessages';
import {
  ErrorType,
  ImportFileType,
  ImportStep,
  ImportStepName,
} from '../../enums';
import { FileUploader } from '../FileUpload/FileUploader';
import { Footer } from '../Footer';
import { TypeSelector } from '../DataTypeSelection/TypeSelector';
import style from './Site.module.scss';
import { MappingTable } from '../DataMapping/MappingTable';
import { ImportFinish } from '../ImportCompletion/ImportFinish';
import { ImportPreview } from '../ImportPreview';
import { ImportStatus } from '../ImportStatus';

export const Site: FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [importTypes, setImportTypes] = useState([]);
  const [userName, setUserName] = useState('');
  const currentStep = useCurrentStep();
  const currentStepName = currentStep!.stepName;
  const accountId = useAccountId();
  const dispatch = useDispatch();

  const fetchUserProfile = useCallback(async () => {
    try {
      setIsLoading(true);
      const { data, status: resStatus } = await getUserProfile();
      if (resStatus === 404) {
        window.location.assign(
          `${api.defaults.baseURL}/auth/sso?returnUrl=${window.location.href}`
        );
      } else {
        setUserName(data.username);
        dispatch(setAccountId(data.accountId));
      }
    } catch (e) {
      window.location.assign(
        `${api.defaults.baseURL}/auth/sso?returnUrl=${window.location.href}`
      );
    }
  }, [dispatch]);

  const fetchSyncStatus = useCallback(async () => {
    try {
      const data = await getImportStatus();
      if (data.currentUserStatus.step !== 0) {
        dispatch(
          setUploadedFile({
            fileName: data.currentUserStatus.fileName,
            fileUploaded: data.currentUserStatus.fileName.length > 0 && true,
          })
        );
        dispatch(
          selectType(
            ImportFileType[data.importFileType as keyof typeof ImportFileType]
          )
        );
      }

      switch (data.currentUserStatus.step) {
        case ImportStep.FieldMapping: {
          dispatch(
            setStepStatus({ id: ImportStep.FileUpload, stepFinished: true })
          );
          dispatch(stepMove(ImportStep.FieldMapping));
          setIsLoading(false);
          break;
        }
        case ImportStep.Preview: {
          const { status } = await getValidationProcess();
          dispatch(
            setStepStatus({ id: ImportStep.FileUpload, stepFinished: true })
          );
          if (status?.toLowerCase().includes('finished')) {
            dispatch(setPreviewState(true));
            dispatch(stepMove(ImportStep.Preview));
            dispatch(
              setStepStatus({ id: ImportStep.FieldMapping, stepFinished: true })
            );
          } else dispatch(stepMove(ImportStep.FieldMapping));
          setIsLoading(false);
          break;
        }
        default: {
          dispatch(stepMove(data.currentUserStatus.step));
          setIsLoading(false);
          break;
        }
      }
    } catch (e) {
      Notification(ErrorType.GeneralError, {
        message: 'Error',
        description: genericError,
      });
    }
  }, [dispatch]);

  const fetchImportTypes = useCallback(async () => {
    try {
      const data = await getImportTypes();
      const enumData = data.map(
        (x: any) => ImportFileType[x as keyof typeof ImportFileType]
      );
      setImportTypes(enumData);
    } catch (e) {
      Notification(ErrorType.GeneralError, {
        message: 'Error',
        description: genericError,
      });
    }
  }, []);

  useEffect(() => {
    if (accountId) fetchSyncStatus();
    else fetchUserProfile();
  }, [accountId, fetchUserProfile, fetchSyncStatus]);

  useEffect(() => {
    if (accountId && currentStepName === ImportStepName.None)
      fetchImportTypes();
  }, [currentStepName, accountId, fetchImportTypes]);

  const classNames = [
    style.page,
    currentStepName === ImportStepName.None && style.pagePrimary,
  ]
    .filter(Boolean)
    .join(' ');

  return (
    <div className={classNames} data-testid="site">
      {!accountId ? (
        <Spin />
      ) : (
        <>
          <Header userName={userName} />
          {isLoading ? (
            <Spin />
          ) : (
            <>
              {currentStepName !== ImportStepName.None && <ImportStatus />}
              <main className={style.pageContent}>
                <div
                  className={
                    currentStepName !== 'Preview'
                      ? style.pageContentInner
                      : style.previewContent
                  }
                >
                  {currentStepName === ImportStepName.None && (
                    <TypeSelector types={importTypes} />
                  )}
                  {currentStepName === ImportStepName.FileUpload && (
                    <FileUploader />
                  )}
                  {currentStepName === ImportStepName.FieldMapping && (
                    <MappingTable />
                  )}
                  {currentStepName === ImportStepName.Preview && (
                    <ImportPreview />
                  )}
                  {currentStepName === ImportStepName.Finish && (
                    <ImportFinish />
                  )}
                </div>
              </main>
              {currentStepName === ImportStepName.None ||
              (currentStepName === ImportStepName.Finish &&
                currentStep.stepFinished) ? null : (
                <Footer />
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default Site;
