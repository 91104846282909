import { Steps } from 'types';

export const importSteps: Steps[] = [
  {
    id: 0,
    stepName: 'None',
    stepFinished: false,
  },
  {
    id: 1,
    stepName: 'File Upload',
    stepFinished: false,
  },
  {
    id: 2,
    stepName: 'Field Mapping',
    stepFinished: false,
  },
  {
    id: 3,
    stepName: 'Preview',
    stepFinished: false,
  },
  {
    id: 4,
    stepName: 'Finish',
    stepFinished: false,
  },
];
