import { Checkbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { useDispatch } from 'react-redux';
import { FooterNotification } from 'components/FooterNotification';
import useRowsWithError from 'hooks/useRowsWithError';
import useRowsToImportCount from 'hooks/useRowsToImportCount';
import { setStepStatus } from 'reducers/DataImportSlice';
import { ImportStep } from 'enums';
import style from './ImportRowsAccept.module.scss';

export const ImportRowsAccept = () => {
  const rowsToImport = useRowsToImportCount();
  const rowsWithErrorCount = useRowsWithError();
  const dispatch = useDispatch();

  const onChange = (e: CheckboxChangeEvent) => {
    if (e.target.checked) {
      dispatch(setStepStatus({ id: ImportStep.Preview, stepFinished: true }));
    } else {
      dispatch(setStepStatus({ id: ImportStep.Preview, stepFinished: false }));
    }
  };

  return (
    <FooterNotification>
      <div className={style.rowsInfo}>
        <span>
          <strong>{rowsToImport}</strong> Rows will be imported
        </span>
      </div>
      {rowsWithErrorCount > 0 && rowsToImport !== 0 && (
        <div>
          <Checkbox className={style.rowsCheckbox} onChange={onChange}>
            <span className={style.errorRows}>
              {`${rowsWithErrorCount} Rows will not be imported, check this box to continue.`}
            </span>
          </Checkbox>
        </div>
      )}
    </FooterNotification>
  );
};
