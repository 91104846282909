import { Spin } from 'components/Spin';
import useDataType from 'hooks/useDataType';
import { ImportLoaderProps } from '.';
import style from './ImportLoader.module.scss';

export const ImportLoader: React.FC<ImportLoaderProps> = (props) => {
  const { processedItemCount, totalItemCount, multipleImportWarning } = props;
  const dataType = useDataType();

  const renderContent = () => {
    if (multipleImportWarning) {
      return <div>{multipleImportWarning}</div>;
    }

    return (
      <>
        <div>Importing</div>
        <div>{dataType}...</div>
        <div>
          <b>
            {totalItemCount > 0
              ? `${processedItemCount} of ${totalItemCount} imported`
              : `calculating...`}
          </b>
        </div>
        <div>do not navigate away</div>
        <div>this could take a while.</div>
      </>
    );
  };

  return (
    <div className={style.importLoader}>
      <Spin active={!multipleImportWarning} />
      <div className={style.loaderContent}>{renderContent()}</div>
    </div>
  );
};
