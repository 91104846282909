import api from 'api/api';
import axios from 'axios';
import { Button, Space } from 'antd';
import { useDispatch } from 'react-redux';
import { Paper } from 'components/Paper';
import useRowsToImportCount from 'hooks/useRowsToImportCount';
import {
  setInitialState,
  setStatus,
  setStepStatus,
} from 'reducers/DataImportSlice';
import useCurrentStep from 'hooks/useCurrentStep';
import { ImportLoader } from 'features/ImportCompletion/ImportLoader';
import { FC, useCallback, useEffect, useState } from 'react';
import { getImportProgress } from 'api/getImportProgress';
import { changeImportStatus } from 'api/changeImportStatus';
import useAccountId from 'hooks/useAccountId';
import useRowsWithError from 'hooks/useRowsWithError';
import useDataType from 'hooks/useDataType';
import { startImport } from 'api/startImport';
import useCurrentStatus from 'hooks/useCurrentStatus';
import { Notification } from 'components/Notification';
import { genericError, multipleImportWarning } from 'helpers/errorMessages';
import useEffectOnce from 'hooks/useEffectOnce';
import { ImportFileType, ImportStatus, ErrorType } from '../../../enums';
import style from './ImportFinish.module.scss';

export const ImportFinish: FC = () => {
  const [isMultipleImport, setIsMultipleImport] = useState(false);
  const [importStatus, setImportStatus] = useState('');
  const [processedItemCount, setProcessedItemCount] = useState(0);
  const [totalItemCount, setTotalItemCount] = useState(0);
  const [error, setError] = useState('');
  const rowsToImport = useRowsToImportCount();
  const rowsWithError = useRowsWithError();
  const accountId = useAccountId();
  const currentStep = useCurrentStep();
  const currentStatus = useCurrentStatus();
  const dataType = useDataType();
  const dispatch = useDispatch();

  const resetState = () => {
    dispatch(setInitialState());
  };

  const completeImport = useCallback(async () => {
    try {
      await changeImportStatus({
        accountId,
        status: 0,
        step: 0,
        fileName: '',
        fileUrl: '',
        mappedFields: {},
        importFileType: ImportFileType.None,
      });
    } catch (e) {
      Notification(ErrorType.GeneralError, {
        message: 'Error',
        description: genericError,
      });
    }
  }, [accountId]);

  const startImportProcess = useCallback(async () => {
    try {
      const data = await startImport();
      setImportStatus(data);
    } catch (e) {
      if (axios.isAxiosError(e)) {
        if (e.response?.status === 409) {
          setIsMultipleImport(true);
          Notification(ErrorType.Warning, {
            message: 'Warning',
            description: multipleImportWarning,
          });
        } else {
          Notification(ErrorType.GeneralError, {
            message: 'Error',
            description: genericError,
          });
        }
      }
    }
  }, []);

  useEffectOnce(() => {
    startImportProcess();
  });

  const fetchImportProgress = useCallback(async () => {
    try {
      const data = await getImportProgress();
      dispatch(setStatus(data.status));
      setProcessedItemCount(data.processedItemCount);
      setTotalItemCount(data.totalItemCount);
      if (data.status === ImportStatus.None) {
        dispatch(setStepStatus({ id: currentStep.id, stepFinished: true }));
      }
      if (data.status === ImportStatus.Error) {
        dispatch(setStepStatus({ id: currentStep.id, stepFinished: true }));
        setError(data.error);
      }
    } catch (e) {
      setImportStatus('');
      Notification(ErrorType.GeneralError, {
        message: 'Error',
        description: genericError,
      });
    }
  }, [currentStep, dispatch]);

  useEffect(() => {
    let intervalId = 0;
    if (
      !currentStep.stepFinished &&
      importStatus.includes('started') &&
      !isMultipleImport
    ) {
      intervalId = window.setInterval(() => {
        fetchImportProgress();
      }, 1000);
    } else clearInterval(intervalId);
    return () => clearInterval(intervalId);
  }, [
    dispatch,
    fetchImportProgress,
    importStatus,
    currentStep.stepFinished,
    isMultipleImport,
  ]);

  useEffect(() => {
    if (currentStep.stepFinished) {
      completeImport();
    }
  }, [currentStep, completeImport]);

  return (
    <Paper className={style.importSuccess}>
      {!currentStep.stepFinished ? (
        <ImportLoader
          processedItemCount={processedItemCount}
          totalItemCount={totalItemCount}
          multipleImportWarning={isMultipleImport ? multipleImportWarning : ''}
        />
      ) : (
        <Space direction="vertical" size={20}>
          <h3 className={style.successHeader}>
            {currentStatus === ImportStatus.Error ? 'Error!' : 'Success!'}
          </h3>
          {error && <div>{error}</div>}
          <div>
            {`${rowsToImport} of ${
              rowsToImport + rowsWithError
            } rows were imported.`}
          </div>
          <Button
            type="primary"
            href={`${api.defaults.baseURL}/navigation/navigateto/${
              dataType?.toLowerCase() ?? 'customer'
            }`}
            className={style.viewAllLink}
          >
            View All {dataType}
          </Button>
          <Button ghost type="primary" onClick={resetState}>
            Import More
          </Button>
        </Space>
      )}
    </Paper>
  );
};
