import { Space } from 'antd';
import useAllSteps from 'hooks/useAllSteps';
import useDataType from 'hooks/useDataType';
import { Steps } from 'types';
import { Step } from '../Step';
import style from './ImportSteps.module.scss';

export const ImportSteps = () => {
  const dataType = useDataType();

  const steps = useAllSteps();

  return (
    <Space direction="vertical" align="center" size="large">
      <h2>Import {dataType}</h2>
      <div className={style.importSteps}>
        {steps.map((step, i: number, arr: Steps[]) => (
          <Step
            key={step.stepName}
            stepName={step.stepName!}
            showLine={arr.length - 1 !== i}
          />
        ))}
      </div>
    </Space>
  );
};
