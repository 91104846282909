import { useSelector } from 'react-redux';
import { DATA_IMPORT_SLICE } from '../reducers/DataImportSlice';
import { RootState } from '../types';

const useStep = (stepName: string | undefined) =>
  useSelector((state: RootState) =>
    state[DATA_IMPORT_SLICE].steps.find((step) => step.stepName === stepName)
  );

export default useStep;
