import api from './api';

interface Column {
  columnHeader: string;
  value: string[];
  doNotImport?: boolean;
}

export async function getFilePreview() {
  return api.post<Column[]>('/file/preview').then(({ data }) => data);
}
